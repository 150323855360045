.recipesPage {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

.recipeList {
  display: flex;
  flex-direction: column;
  width: fit-content;
  box-shadow: 1px 1px 2px lightgray;
  border-radius: 2px;
  background-color: white;
  margin-left: 1rem;
  padding-bottom: 1rem;
  height: 100%;
}

.noRecipes {
  padding: 3rem;
  text-align: center;
  font-family: Lora;
}

@media screen and (max-width: 850px) {
  .recipeList {
    width: 100%;
    margin-left: 0;
  }
}

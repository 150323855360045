.navigationItem a {
  color: #fff;
  text-decoration: none;
  margin: 0px 10px;
  padding: 10px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  letter-spacing: 0.1rem;
}

.navigationItem a:before,
.navigationItem a:after {
  position: absolute;
  opacity: 0;
  width: 0%;
  height: 1px;
  content: '';
  background: rgb(241, 230, 230);
  transition: all 0.3s;
}

.navigationItem a:before {
  right: 0px;
  top: 0px;
}

.navigationItem a:after {
  left: 0px;
  bottom: 0px;
}

.navigationItem a:hover:after,
.navigationItem a.active:after,
.navigationItem a.active:before {
  opacity: 1;
  width: 100%;
}

.logger a {
  border-radius: 2px;
  text-decoration: none;
  color: rgb(241, 241, 236);
  background-color: #986959;
  padding: 0.5rem 1.5rem;
  transition: all 0.2s;
  position: relative;
  transition: all 0.2s ease-in-out;
  box-shadow: 1px 1px 5px #352e2e80;
}

.logger:hover {
  transform: scale(1.025);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)),
    url('./assets/food_background.jpg');
  background-attachment: fixed;
  background-size: cover;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color:#bc5252 !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #bc5252 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.done {
  opacity: 0.3;
  filter: brightness(0.8);
}

label {
  color: black !important;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(106, 50, 50, 0.2);
  padding-top: 2rem;
  min-height: fit-content;
}

.home img {
  width: 100%;
}

.joinMessage {
  background-color: rgba(0, 0, 0, 0.35);
  width: 75%;
  border-radius: 0.5rem;
  text-align: center;
  font-family: Roboto;
}

.joinMessage h2 {
  font-size: 2rem;
  color: #ffffffc5;
}

.joinMessage p {
  color: #ffffffc5;
  font-size: 1.5rem;
}

.lastRecipesCards {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: space-between;
  flex-wrap: wrap;
}

.lastRecipes {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lastRecipes h2 {
  text-align: center;
  color: white;
  font-family: 'Roboto';
  font-weight: 370;
  font-size: 2.5rem;
  letter-spacing: 0.5rem;
  text-shadow: 1px 1px black;
  margin: 1rem;
}

.noRecipes {
  padding: 2rem;
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.854);
  border-radius: 0.5rem;
  color: #3a2323;
  margin: 2rem;
  text-align: center;
}

.noRecipes a {
  color: #953838;
}

.noRecipes a:hover {
  color: #291246;
}

.searchBar {
  width: 60%;
}

.logo {
  width: 60%;
}

@media screen and (max-width: 800px) {
  .logo {
    width: 80%;
  }

  .searchBar {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .home {
    height: 100%;
  }

  .home > * {
    margin-top: 4rem;
  }

  .joinMessage {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

@media screen and (max-width: 1200px) {
  .home > * {
    margin-top: 3rem;
  }
}

.recipe {
  font-family: Roboto;
  font-weight: lighter;
  font-size: small;
  margin: 1rem 1rem 0 1rem;
  max-width: 50vw;
  display: flex;
}

.recipe a {
  text-decoration: none;
  color: black;
}

.recipe h2 {
  font-weight: 400;
  margin: 0;
  font-family: Lora;
}

.rating {
  display: flex;
}

.rating p {
  margin: 0 0.5rem 0 0;
}

.rating .score {
  color: grey;
  margin-top: 0.1rem;
}

.recipe img {
  border-radius: 0.2rem;
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  margin-right: 1rem;
  transition: 0.2s all;
}

.recipeInfo {
  width: 100%;
}

.firstRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actionButtons {
  display: flex;
  margin-top: 0.15rem;
}

.actionButtons button {
  background-color: white;
}

.actionButtons > * {
  color: #cd8282;
  cursor: pointer;
  border: none;
  background-color: none;
}

@media screen and (max-width: 850px) {
  .recipe {
    max-width: 100%;
    box-shadow: 0px 0px 3px lightgray;
    padding: 0.5rem;
  }

  .extraInfo {
    display: none;
  }

  .recipe {
    flex-direction: column;
  }

  .recipe img {
    width: 100%;
    height: 100%;
  }
}

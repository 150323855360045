.navigationItems {
  display: flex;
  justify-content: space-around;
  list-style: none;
}

@media screen and (max-width: 1200px) {
  .navigationItems {
    flex-direction: column;
    justify-content: space-around;
  }
}

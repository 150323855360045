.types {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}

.checkbox {
  position: relative;
}

.checkbox input {
  position: absolute;
  right: 0;
  accent-color: #bc5252;
}

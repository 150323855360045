.card {
  padding: 0.5rem;
  height: 20rem;
  max-width: 20rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin: 1rem;
}

.card p {
  text-align: center;
  font-size: 1.25rem;
  font-family: lora;
  margin: 0.5rem 0;
  color: #000000;
  text-decoration: none;
}

.lastRecipe {
  background-color: #fff8f8;
  color: black;
}

.category {
  background-color: #ffce97;
}

.card img {
  height: 85%;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
}

@media (max-width: 1200px) {
  .card {
    padding: 0.5rem;
    min-height: fit-content;
    border-radius: 0.5rem;
    cursor: pointer;
    margin: 2rem;
  }
}

@media (max-width: 820px) {
  .card {
    padding: 0.5rem;
    width: 80%;
    min-height: fit-content;
    border-radius: 0.5rem;
    cursor: pointer;
    margin: 2rem;
  }
}

.toolbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
  background-color: #8b4242;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
}

.desktopOnly {
  width: 80%;
}

@media (max-width: 1200px) {
  .desktopOnly {
    display: none;
  }
}

.recipes {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  margin: 8rem auto 2rem;
  max-width: 70%;
  width: fit-content;
  padding: 2rem;
  border-radius: 5px;
  background-color: rgb(229, 216, 207);
}

.userRecipes {
  background-color: rgb(241, 236, 245);
}

.top {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addRecipe {
  background: #bc5252;
  color: white;
  margin: auto;
  margin-left: 0;
  margin-right: 1.5rem;
  padding: 0 1.5rem;
  height: 2.5rem;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 350;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  transition: 0.2s all;
  cursor: pointer;
}

.fullSearchBar {
  width: 100%;
}

.searchBar {
  width: 79%;
}

.middle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.filterIcon {
  color: #8b4242;
  cursor: pointer;
  margin-right: 1rem;
  border: none;
  border-radius: 5px;
  display: none;
}

@media screen and (max-width: 850px) {
  .filterIcon {
    display: block;
    background-color: white;
  }

  .top {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .addRecipe {
    height: 3rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  .searchBar {
    width: 100%;
  }

  .recipes {
    max-width: 100%;

    margin: 0;
    padding: 1rem;
  }
}

.recipes button:hover {
  transform: scale(1.025);
  filter: brightness(1.05);
}

.recipes button:active {
  transform: scale(1);
}

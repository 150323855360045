.drawerToggle {
  width: 4rem;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 1rem 0;
  margin-right: 1rem;
  box-sizing: border-box;
  cursor: pointer;
}

.drawerToggle div {
  width: 80%;
  height: 0.15rem;
  background-color: #fbf3ea;
  border-radius: 2px;
}

@media (min-width: 800px) {
  .drawerToggle div {
    width: 90%;
    height: 0.25rem;
    background-color: #fbf3ea;
    border-radius: 2px;
  }
}

@media (min-width: 1201px) {
  .drawerToggle {
    display: none;
  }
}

.signUpWindow {
  background-color: rgb(250, 250, 250, 0.85);
  border-radius: 0.5rem;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  width: 35%;
  margin: auto;
  margin-bottom: 2rem;
  padding: 3rem 2rem;
}

.signUpWindow form {
  background-color: rgb(236, 233, 233);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  padding: 3rem 3rem;
  margin-top: 2rem;
  text-align: left;
}

.namesFields {
  display: flex;
  flex-direction: row;
}

.firstname {
  margin-right: 1rem;
  width: 100%;
}

.unvalidDate {
  color: #f44336;
  margin-top: 0.2rem;
  line-height: 0;
  font-size: 0.8rem;
}

.signUpButton {
  background: #b76565;
  color: white;
  margin: 0 auto 0;
  width: 100%;
  height: 2rem;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 350;
  border-radius: 0.2rem;
  outline: none;
  border: none;
  transition: 0.2s all;
  cursor: pointer;
}

.signUpWindow input:hover {
  transform: scale(1.01);
  filter: brightness(1.05);
}

.signUpWindow input:active {
  transform: scale(1);
}

@media screen and (max-width: 1450px) {
  .signUpWindow {
    width: 45%;
  }
}

@media screen and (max-width: 1200px) {
  .signUpWindow {
    margin-top: 4rem;
    width: 50%;
  }
}

@media screen and (max-width: 950px) {
  .signUpWindow {
    width: 65%;
  }
}

@media screen and (max-width: 650px) {
  .signUpWindow {
    width: 75%;
    margin-top: 3rem;
    padding-top: 0.5rem;
  }

  .namesFields {
    flex-direction: column;
  }
}
